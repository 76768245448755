import request from '@/tools/request';
import { Res } from '@/types/common';
import { GatewayData } from '../types/board';

export const getConfig = (loading: any) => {
  return request({
    url: '/fbadsboard-index-config.html',
    loading,
  }) as Promise<Res<GatewayData>>;
};
