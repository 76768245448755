
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { GatewayData } from '../types/board';
import '../statics/scss/iconfont.scss';
import { getConfig } from '../api/board';
import { copy } from '@/tools';
import { ElMessage } from 'element-plus';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';

export default defineComponent({
  components: { PageHeader, FormSkeleton },
  setup() {
    const formData = ref<GatewayData>({});

    const loading = ref(false);
    const initConf = async () => {
      const { data } = await getConfig(loading);
      if (!data) return;
      formData.value = data;
    };

    const copyText = text => {
      copy(text);
      ElMessage.success('复制成功');
    };

    onMounted(() => {
      initConf();
    });

    return {
      formData,
      copyText,
      loading,
    };
  },
});
